<template>
  <div ref="scrollTopButton" class="c-scroll sticky w-full flex justify-end bottom-0 pb-3 pr-5 transition invisible">
    <div class="transition">
      <button @click="scrollToTop" role="button" aria-label="scroll to top of the page" class="c-scroll__button">
        <font-awesome-icon :icon="appIconArrowUp" />
      </button>
    </div>
  </div>
</template>

<script>
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'ScrollTopComponent',
  data() {
    return {
      appIconArrowUp: faArrowUp
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      const scrollBtn = this.$refs.scrollTopButton;

      if (window.scrollY > 0) {
        scrollBtn.classList.remove("invisible");
      } else {
        scrollBtn.classList.add("invisible");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
