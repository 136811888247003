<template>
    <footer class="s-footer">
      We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with SIDEARM Sports, or any of its subsidiaries or its affiliates.
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  }
  </script>
  