<template>
  <li class="s-school__item text-center shadow place-content-center">
    <a :href="`${school.client_url}`" class="s-school__link p-4" target="blank">
      <img 
        loading="lazy" 
        v-if="!school.client_image_url" 
        src="https://stats-prod.sidearmsports.com/assets/sidearm.svg" 
        width="150" 
        class="s-school__logo mx-auto" 
        :alt="`${school.client_name}` + ' logo'"
      >
      <img 
        loading="lazy" 
        v-if="school.client_image_url" 
        :src="`${school.client_image_url}`" 
        width="150" 
        class="s-school__logo mx-auto" 
        :alt="`${school.client_name}` + ' logo'"
      >
      <div class="s-school__name pt-4">{{school.client_name}}</div>
      <div class="s-school__icon p-2">
        <font-awesome-icon :icon="appIconExternal" />
      </div>
    </a>
  </li>
</template>

<script>
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'SchoolComponent',
  data() {
    return {
      appIconExternal: faExternalLinkAlt,
    }
  },
  props: {
    school: {
      type: Object,
      required: true
    }
  },
}
</script>
