<template>
    <header class="s-header">
      <div class="container flex justify-between items-center mx-auto px-4">
        <h1 class="text-center pt-3 pb-3">SIDEARM Fam</h1>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderComponent'
  }
  </script>

<style lang="scss" scoped>
  h1{
    font-size:1.5rem;
    font-weight:600;
    text-transform: uppercase;
    color:#fff;
  }
</style>
  