<template>
  <div id="app">
    <HeaderComponent />
    <div class="s-main container mx-auto px-4">
      <h2 class="heading text-center mt-6">Recently Launched</h2>
      <ul class="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 gap-4">
        <SchoolComponent v-for="school in recentList.sites" :key="school.id" :school="school" />
      </ul>
      <h2 class="heading text-center pt-6">All Clients - {{ totalClients }}</h2>
      <div class="s-main__buttons">
        <button type="button" class="s-main__button text-white font-bold py-2 px-4 rounded-full"
          @click="handleClick('All')"
        >
          All
        </button>
        <button type="button" class="s-main__button text-white font-bold py-2 px-4 rounded-full"
          v-for="letter in alphabet" 
          :key="letter" 
          @click="handleClick(letter)"
        >{{ letter.toUpperCase() }}</button>
    </div>
    <p v-if="noSchoolsMessage">{{ noSchoolsMessage }}</p>
    <ul class="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 gap-4">
      <SchoolComponent 
        v-for="school in filteredSchools" 
        :key="school.id" 
        :school="school"
      />
    </ul>
    <button v-if="showLoadMoreButton" @click="loadMore" class="s-main__load-more">Load More</button>
    </div>
    <FooterComponent />
    <ScrollTopComponent />
  </div>
</template>


<script>
import HeaderComponent from './components/HeaderComponent.vue'
import SchoolComponent from './components/SchoolComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import ScrollTopComponent from './components/ScrollTopComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    SchoolComponent,
    FooterComponent,
    ScrollTopComponent
  },
  data() {
    return {
      schoolsList: [],
      recentList: [],
      limit: 25,
      busy: false,
      filteredSchools: [],
      selectedLetter: 'All',
      noSchoolsMessage: '',
      showLoadMoreButton: false
    };
  },
  methods: {
    fetchData(){
      this.busy = true;
      fetch("https://services.sidearmsports.com/services/clients.ashx")
        .then(response => response.json())
        .then(data => {
          this.schoolsList = data;
          this.filteredSchools = this.schoolsList.sites.slice(0, this.limit);
          this.showLoadMoreButton = this.schoolsList.sites.length > this.limit;
        });
    },
    fetchRecent() {
      fetch("https://services.sidearmsports.com/services/clients.ashx?type=recent")
        .then(response => response.json())
        .then(data => (this.recentList = data));
    },
    handleClick(letter) {
      this.selectedLetter = letter;
      if (letter === 'All') {
        this.filteredSchools = this.schoolsList.sites.slice(0, this.limit);
        this.noSchoolsMessage = '';
        this.showLoadMoreButton = this.schoolsList.sites.length > this.limit;
      } else {
        this.filteredSchools = this.schoolsList.sites.filter(school => school.client_name.toLowerCase().startsWith(letter.toLowerCase()));
        this.showLoadMoreButton = false;
        this.noSchoolsMessage = this.filteredSchools.length === 0 ? `There are no schools starting with ${letter}` : '';
      }
    },
    loadMore() {
      if (this.selectedLetter === 'All') {
        let currentLength = this.filteredSchools.length;
        let moreSchools = this.schoolsList.sites.slice(currentLength, currentLength + this.limit);
        this.filteredSchools = [...this.filteredSchools, ...moreSchools];
        this.showLoadMoreButton = this.schoolsList.sites.length > this.filteredSchools.length;
      }
    }
  },
  computed: {
    totalClients() {
      return this.schoolsList.sites?.length;
    },
    alphabet() {
      return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
    }
  },
  created() {
    this.fetchRecent();
    this.fetchData();
  },
}
</script>



<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins');

  $background:#25384b;
  #app{
    font-family: "Poppins",sans-serif !important;
  }
  h1{
    font-size:1.5rem;
    font-weight:600;
    text-transform: uppercase;
    color:#fff;
  }
  .heading{
    font-size:1.5rem;
  }
  .s-header{
    background-color: $background;
    position:fixed;
    top:0;
    width:100%;
    z-index:2
  }
  .s-main{
    margin-top:6rem;
    margin-bottom:6rem;
    &__buttons{
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      margin:1.2rem 0
    }
    &__button{
      background-color: $background;
      margin:.2rem .2rem;
      min-width: 2.8rem;
      &:hover{
        background-color:#ff8e30;
      }
    }
    &__load-more{
      margin: 2.4rem auto 0 auto;
      display: flex;
      background-color: $background;
      min-width: 2.8rem;
      color:#fff;
      padding:1.2rem 1.6rem;
      border-radius: 2rem;
      transition:.25s;
      &:hover{
        background-color:#ff8e30;
      }
    }
  }
  .s-count{
    color:#ff8e30;
  } 
  .s-school__link{
    height: 100%;
    display: inline-block;
    width: 100%;
    position:relative;
    &:hover{
      .s-school__logo{
        transform: scale(1.1);
      }
      .s-school__icon{
        color:#ff8e30;
        transition:.25s;
      }
    }
  }
  .s-school__logo{
    transform: scale(1);
    transition:.25s;
  }
  .s-school__icon{
    position:absolute;
    top:0;
    right:0;
    transition:.25s;
    color:$background;
  }
  .s-footer{
    text-align:center;
    background-color:$background;
    padding:1.2rem 0;
    position:fixed;
    bottom:0;
    width: 100%;
    color:#fff;
    z-index:2;
  }
  .c-scroll{
    bottom:4rem;
    &__button{
      background-color: #25384b;
      color: white;
      padding: 0.8rem 1.1rem;
      &:hover{
        color:#ff8e30;
      }
    }
  }
</style>